import React from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import { CookiesProvider, useCookies } from 'react-cookie';
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyles } from './theme/GlobalStyles';
import {useTheme} from './theme/useTheme';

import Home from './views/Home'
import NotFound from './components/NotFound'
import Experience from './components/individual'
import Shopping from './views/shopping-cart'
import PaymentResult from './views/payment-result'

import { ShoppingProvider } from './serviceContext/ShoppingContext'
import ExperiencesGuide from './views/ExperiencesGuide';

export default function App() {
    const [cookies, ,] = useCookies(['payment']);
    const payment = cookies.payment !== undefined;
    const {theme, themeLoaded, getFonts} = useTheme();
  const [selectedTheme, setSelectedTheme] = React.useState<any>(theme);

  React.useEffect(() => {

    setSelectedTheme(theme);
   }, [theme]);

    const Container = styled.div`
    margin: 5px auto 5px auto;
    `;

    return (
        <BrowserRouter>
            <CookiesProvider>
                <ShoppingProvider>
                    {
                      themeLoaded && <ThemeProvider theme={ selectedTheme }>
                        <Container style={{fontFamily: selectedTheme.font}}>
                            <div className="App"> 
                                <Switch>
                                    <Route exact path="/" component={Home}/>
                                    <Route path="/view/owner/:ownerid/:ownername" component={ExperiencesGuide} />
                                    <Route path="/view/:id/:name" component={Experience} />

                                    <Route  path="/checkout" component={Shopping} />
                                    <Route  path="/result/:resultBool/:email" component={PaymentResult} >
                                        {!payment && <Redirect to="/" />}
                                    </Route>
                                    <Route path="*" component={NotFound} />
                                </Switch>
                            </div>
                        </Container>
                    </ThemeProvider>
                  }
                </ShoppingProvider>
            </CookiesProvider>
        </BrowserRouter>
  );  
}
import React, { useState } from 'react';
import Header from '../components/Header'
import {
  Box
} from "@mui/material";
import {
    useParams
} from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useShopping, useShoppingUpdate } from '../serviceContext/ShoppingContext'

const PaymentResult = () => {
	const shoppingList = useShopping()
    const shoppingProperties = useShoppingUpdate()
    const [ , , removeCookie ] = useCookies(['payment']);
    removeCookie('payment', { path: '/' })

    let {resultBool}: any = useParams();
    let {email}: any = useParams();																																																																																																																																																																																																																																																																																																
    let msg = "Your payment was canceled, if this is an error contact us at admin@playeatlas.com."
    if(resultBool === "success") {
		msg = "Congratulations! Your payment succeeded and an email with your purchase code(s) has been sent to " + email + ".  If you do not receive that email, please contact us at admin@playeatlas.com."
		if(shoppingList.length > 0) {
            shoppingProperties.removeAll();
        }
	}
    		return (
		<div>
			<Header currentPage={'PaymentResult'}/>
			<Box sx={{ m: "100px auto", width: "900px" }}>
				<h1>{msg}</h1>
			</Box>		
    	</div>
	);
}

export default PaymentResult;
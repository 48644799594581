import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import firebase from "../firebase/firebase"
import CircularProgress from '@mui/material/CircularProgress';
import StarIcon from '@mui/icons-material/Star';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { amber } from '@mui/material/colors';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import Map, {Marker} from 'react-map-gl';
import {
    useParams
} from "react-router-dom";
import { loadMapApi } from '../utils/GoogleMapsUtils';
import Header from './Header';
import { useShopping, useShoppingUpdate } from '../serviceContext/ShoppingContext';
import '../css/individual.css';
import { useHistory } from "react-router-dom";
import {useTheme} from '../theme/useTheme';
import markerIcon from '../assets/images/marker_icon.png';
import {Helmet} from "react-helmet";  

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
    backgroundColor: '#f2f2f2',
    lineHeight: '30px',
    padding: 2
}));

const Experience = () => {

    let { id }: any = useParams();
    const shoppingList = useShopping();
    const shoopingFunctions = useShoppingUpdate();
    const [shoppingCartIds, setShoppingCartIds] = useState<Array<String>>([]);
    const [stars, setStars] = useState<number>(0);
    const [experience, setExperience] = useState<any>(null);
    const [lastExperiences, setLastExperiences] = useState<any>([])
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    const [screenWidth, setScreenWidth] = useState<Number>(window.innerWidth);
    const [ratings, setRatings] = useState<Array<any>>([]);
    const {theme, themeLoaded, getFonts} = useTheme();
    const [selectedTheme, setSelectedTheme] = React.useState<any>(theme);
    const [widthScreen, setWidthScreen] = useState<number>(0);

    const history = useHistory();

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3// optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    useEffect(() => {
        const { innerWidth: width } = window;
        setWidthScreen(width);
    }, [])
    

    useEffect(() => {
        fillIdsArray();
    }, [shoppingList]);

    useEffect(() => {

        if(experience){
            getLastExperiences();
        }     
    }, [experience]);

  useEffect(() => {
    setSelectedTheme(theme);
   }, [theme]);

    useEffect(() => {
        getExperience();
        getExperienceRatings();
        const googleMapScript = loadMapApi();
        googleMapScript.addEventListener('load', () => {
            setScriptLoaded(true);
        });
        const onResize = () => {
            setScreenWidth(window.innerWidth);
        }
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);

    const toLastExperience = (id: string, name: string): void => {
        let nameWithDashes = name.replace(/\s+/g, '-');
        var url = `/view/${id}/${nameWithDashes}`;
        history.push(url);
        window.location.reload();

    }

    const getExperience = (): void => {
        const ref = firebase.firestore().collection('events').doc(id);
        ref.get()
            .then((snapshot) => {
                //console.log('-snapshot.data()', snapshot.data())
                setExperience(snapshot.data());
                const numStars = snapshot.data()?.averageRating;
                setStars(Math.round(numStars));
            });
    }

    const getLastExperiences = (): void => {
        firebase.firestore().collection('events').where('details.owner', '==', experience.details.owner).orderBy('createdAt', 'desc')
            .get()
            .then((querySnapshot) => {
                let tempLastExperiences: Array<any> = [];
                querySnapshot.forEach((doc) => {
                    if(doc.data().id !== id){
                        if(doc.data().details){
                            if(doc.data().details.status === 'published'){
                                if(!doc.data().isDeleted){
                                    tempLastExperiences.push(doc.data());
                                } 
                            }
                        }
                    }
                })
                setLastExperiences(tempLastExperiences);
            })
    }

    const getExperienceRatings = (): void => {
        firebase.firestore().collection('events/'+id+'/ratings')
            .get()
            .then((querySnapshot) => {
                let experienceRatings: Array<any> = [];
                querySnapshot.forEach((doc) => {
                    experienceRatings.push(doc.data());
                })
                setRatings(experienceRatings);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const fillIdsArray = () => {
        let idsArray: Array<String> = [];
        for (let item of shoppingList) {
            idsArray.push(item.id);
            if (item.id === id) {
                setIsAdded(true);
            }
        }
        setShoppingCartIds(idsArray);
    };

    const addToShoppingList = (item: any) => {
        let itemExperience = {
            price: item.details.pricing,
            title: item.title,
            id: item.id,
            city: item.city,
            bannerImage: item.bannerImage,
            ownerName: item.ownerName
        }
        shoopingFunctions.addItem(itemExperience)
    }

    const CustomButton = () => {
        if (shoppingCartIds.includes(experience.id.toString())) {
            return <>
                <Button
                    onClick={() => {
                        addToShoppingList(experience);
                    }}
                    sx={{
                        mt: '20px', textTransform: 'none',
                        fontSize: "20px",
                        lineHeight: "25px",
                        backgroundColor: selectedTheme.colors.button.disabled
                    }}
                    disabled={true}>
                    You already have this in your cart
                </Button>
            </>
        } else {
            return <>
                <Button sx={{
                    px: 3,
                    py: 2,
                    textAlign: 'center',
                    borderRadius: "20px",
                    fontWeight: 'bold',
                    bgcolor: selectedTheme.colors.button.background,
                    color: 'white',
                    fontFamily: selectedTheme.font,
                    boxShadow: 'none',
                    textTransform: 'none',
                    fontSize: "20px",
                    lineHeight: "25px",
                    '&:hover': {
                        bgcolor: selectedTheme.colors.button.hover,
                    },
                }}
                    onClick={() => {
                        addToShoppingList(experience);
                    }}
                >Purchase Experience</Button>
            </>
        }
    };


    const Description = () => {
        if (screenWidth <= 768) {
            return (
                <div className="shrinkable" >

                    <Grid item sx={{
                        width: "90%",
                    }}>
                        <h1>{experience.title}</h1>
                    </Grid>
                    <Grid item sx={{
                        width: `90%`,
                    }}  >
                         {
                            experience.promotionMedia ?
                              <CardMedia
                                sx={{
                                    width: "90%",
                                    height: 250,
                                    borderRadius: 5,
                                    objectFit: "cover",
                                    boxShadow: 8,
                                    m: "0 auto"
                                }}
                                allow="autoPlay"
                                component="iframe"
                                src={'https://www.youtube.com/embed/'+experience.promotionMedia.substr(-11,11)}
                                />
                            :
                                <Box component="img" sx={{
                                    width: "100%",
                                    height: 250,
                                    borderRadius: 5,
                                    objectFit: "cover",
                                    boxShadow: 8,
                                    m: "0 auto"
                                }}
                                    src={experience.bannerImage}
                                ></Box>
                        }
                    </Grid>
                    <Grid item sx={{
                        width: "90%",
                        display: "flex",
                        justifyContent: "center",
                        mt: 3
                    }}>
                        <CustomButton />
                    </Grid>
                    <Grid container rowSpacing={2} direction="column" item sx={{
                        width: "90%",
                        my: 4
                    }}>
                        <Grid item>
                            <Item>
                                {[...Array(stars)].map((value: number, index) => {
                                    return <StarIcon sx={{ color: amber[500] }} key={index} ></StarIcon>
                                })} ({ratings?.length})
                            </Item>
                        </Grid>
                        {/*<Grid item >
                            <Item>{experience.objectiveCount} Points of Interest</Item>
                        </Grid>*/}
                        <Grid item >
                            <Item>{experience.duration} minutes</Item>
                        </Grid>
                        <Grid item >
                            <Item>{experience.details.pricing! !== 0 ?
                                experience.details.pricing! % 1 === 0 
                                    ? `$ ${experience.details.pricing}.00 USD` 
                                    : `$ ${experience.details.pricing} USD`
                                : `Free`}</Item>
                        </Grid>
                    </Grid>
                    <Grid sx={{
                        width: "90%",
                    }} container alignItems="start">
                        <p>{experience.address.city}</p>
                    </Grid>
                    <Grid item sx={{
                        width: `90%`,
                    }} container alignItems="start" >
                        <p>Hosted by <b>{experience.details.ownerName}</b>  </p>
                    </Grid>
                    <Grid item sx={{
                        width: `90%`,
                    }} >
                        <div> {experience && experience.longDescription}</div>
                    </Grid>
                    <div className='container-map'>
                        <Item>
                            <Map
                            mapboxAccessToken={`${process.env.REACT_APP_ATLAS_PLATFORM_TOKEN}`}
                            initialViewState={{
                                longitude: experience.address.longitude,
                                latitude: experience.address.latitude,
                                zoom: 14
                            }}
                            style={{ 
                                width: widthScreen > 600 ? 600 : widthScreen * 0.9, 
                                height: 300
                            }}
                            mapStyle="mapbox://styles/mapbox/streets-v9"
                            >
                                <Marker longitude={experience.address.longitude} latitude={experience.address.latitude} anchor="bottom" >
                                    <img src={markerIcon} style={{width: 75, height: 75}} alt="mockup"/>
                                </Marker>
                            </Map>
                        </Item>
                    </div>
                </div>
            );
        } else {
            return (
                <Grid sx={{ margin: "20px auto" }} spacing={4} container >
                    <Grid alignItems="center"
                        container item xs={6} direction='column' sx={{ p: 6, pt: 0 }} >
                        <Grid item sx={{
                        }}>
                            <h1>{experience.title}</h1>
                        </Grid>
                        <Grid sx={{width: '100%', fontSize: '18px', justifyContent: 'space-between', marginBottom: 3}} container item>
                            <span>Hosted by <b>{experience.details.ownerName}</b></span>
                            <span style={{fontWeight: 'normal', textAlign: "right"}}>{experience.address.city}</span>
                        </Grid>
                        <Grid item sx={{
                            fontSize: "16px",
                            lineHeight: 1.5}}>
                            <div className="longDescrip">
                             {experience && experience.longDescription}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}   >
                        <Grid container justifyContent="center" sx={{
                            width: "100%"
                        }}>
                        {
                            experience.promotionMedia ?
                              <CardMedia
                                sx={{
                                    width: "90%",
                                    height: 400,
                                    borderRadius: 5,
                                    objectFit: "cover",
                                    boxShadow: 8,
                                    m: "0 auto"
                                }}
                                allow="autoPlay"
                                component="iframe"
                                src={'https://www.youtube.com/embed/'+experience.promotionMedia.substr(-11,11)}
                                />
                            :
                                <Box component="img" sx={{
                                    width: "90%",
                                    height: 400,
                                    borderRadius: 5,
                                    objectFit: "cover",
                                    boxShadow: 8,
                                    m: "0 auto"
                                }}
                                    src={experience.bannerImage}
                                ></Box>
                        }
                        </Grid>
                        <Grid container justifyContent="center" sx={{
                            my: 4
                        }} >
                            <CustomButton />
                        </Grid>
                        <Grid container rowSpacing={2} direction="column" item sx={{
                            width: '60%',
                            m: "20px auto 40px"
                        }}>
                            <Grid item>
                                <Item sx={{display: "flex", justifyContent: "center"}}>
                                    {[...Array(stars)].map((value: number, i) => {
                                        return <StarIcon sx={{ color: amber[500] }} key={`star-${i}`} ></StarIcon>
                                    })} ({ratings?.length} Reviews) 
                                </Item>
                            </Grid>
                            {/*<Grid item >
                                <Item>{experience.objectiveCount} Points of Interest</Item>
                            </Grid>*/}
                            <Grid item >
                                <Item>{experience.duration} minutes</Item>
                            </Grid>
                            <Grid item >
                            <Item sx={{fontSize: "1.2rem", fontWeight: "bold"}}>{experience.details.pricing! !== 0 ?
                                experience.details.pricing! % 1 === 0 
                                    ? `$ ${experience.details.pricing}.00 USD` 
                                    : `$ ${experience.details.pricing} USD`
                                : `Free`}</Item>
                            </Grid>
                        </Grid>
                            <Map
                                mapboxAccessToken={`${process.env.REACT_APP_ATLAS_PLATFORM_TOKEN}`}
                                initialViewState={{
                                    longitude: experience.address.longitude,
                                    latitude: experience.address.latitude,
                                    zoom: 14
                                }}
                                style={{width: 600, height: 400}}
                                mapStyle="mapbox://styles/mapbox/streets-v9"
                            >
                                <Marker longitude={experience.address.longitude} latitude={experience.address.latitude} anchor="bottom" >
                                    <img src={markerIcon} style={{width: 75, height: 75}} />
                                </Marker>
                            </Map>
                    </Grid>
                </Grid>
            );
        }
    }

    return (
        <div>
             <Helmet>
				<title>{experience ? experience.title : "eAtlas Experience"}</title>
				<meta name="description" content={experience ? experience.title : "eAtlas Experience"} />
				<meta property="og:title" content={experience ? experience.title : "eAtlas Experience"} />        
                <meta property="og:description" content={experience ? experience.title : "eAtlas Experience"} />   
                <meta property="og:url" content="https://quotation.xeleva.com/" />
                <meta property="og:site_name" content="Pets - Products" />
				<meta property="og:image" content={experience ? experience.bannerImage : "https://xeleva.com/imgcalc1.jpg"} />
			</Helmet>
            <Header currentPage={'Shop Experiences'} />
            {experience &&
                <Grid container sx={{ m: "3rem auto", maxWidth: "1400px" }}>
                    <Grid item sx={{
                        mt: 3
                    }}>
                        <Button onClick={() => history.push('/')}> <ArrowBackIcon sx={{ ml: 1 }} color="action" /> Back to results</Button>

                    </Grid>
                    <Description />
                    {(lastExperiences.length > 0) &&
                    <Grid container justifyContent="center"  >
                        <Grid item sx={{my: 3}} >
                            <h1>More from {experience.details.ownerName}</h1>
                        </Grid>
                        <Box sx={{width:"100%"}}>
                        <Carousel
                            swipeable={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            deviceType={screenWidth <= 768 ? "mobile" : "desktop"}
                            itemClass="carousel-item-padding-20-px"
                            containerClass="carousel-container"
                        >
                            {(lastExperiences.length > 0) && lastExperiences.map((e: any) => {
                                return (
                                    <div key={e.id} style={{ width: '70%', cursor: 'pointer', margin: "0 auto", 
                                    backgroundColor: 'white', paddingBottom: "15px", borderRadius: '10px' }} onClick={() => toLastExperience(e.id, e.title)}>
                                        <img className="image" src={e.bannerImage} />
                                        
                                            <h2 style={{ color: "#525252" , textAlign: "center"}}>{e.title}</h2>
                                        
                                    </div>
                                )
                            })
                            }
                        </Carousel>
                        </Box>
                    </Grid>
                    }
                </Grid>
            }
            {!experience && <Grid sx={{
                    mt: 4
            }} container alignContent="center" justifyContent="center" >
                <Grid item>
                    <Box sx={{ display: 'flex', mt: 8 }}>
                        <CircularProgress size="5rem"/>
                    </Box>
                </Grid>
            </Grid>
            }
        </div>
    )
}

export default Experience;

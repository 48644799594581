import React, { useEffect } from "react";

import axios from "axios";
import { AppBar, Toolbar, Box, Grid, Menu, Button, IconButton, Badge, Drawer, List, ListItem, FormGroup, FormControl, FormControlLabel } from "@mui/material";
import { ShoppingCartOutlined, Close, ShoppingCart, Delete } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ContentPaste from "@mui/icons-material/ContentPaste";
import PaymentIcon from '@mui/icons-material/Payment';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Cloud from "@mui/icons-material/Cloud";
import { makeStyles, createStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import { Theme } from "@mui/material";
import Switch, { SwitchProps } from '@mui/material/Switch';
import '../css/navbar.css';
import { getFromLS } from '../utils/storage';
import {useTheme} from '../theme/useTheme';

import { useShopping, useShoppingUpdate } from '../serviceContext/ShoppingContext'
import { useHistory } from "react-router-dom";

const pages = ['Home', 'Adventures', 'Partners', 'Build a Tour', 'FAQs', 'Blog'];

interface INavbar {
  currentPage: string;
}

type Anchor = 'top' | 'left' | 'bottom' | 'right';


const api = axios.create({
  baseURL: `http://localhost:5001`,
});

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const IconMenu = () => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [drawerWidth, setDrawerWidth] = React.useState<Number>(400);
  const [anchorState, setAnchorState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const {theme, themeLoaded, getFonts} = useTheme();
  const [selectedTheme, setSelectedTheme] = React.useState<any>(theme);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const shoppingList = useShopping();
  const shoopingFunctions = useShoppingUpdate();

  const totalWithDiscount = shoopingFunctions.totalOriginal - shoopingFunctions.totalDiscount;
  
  const removeThisItem = (id: string) => {
    shoopingFunctions.removeItem(id)
  }

  const toCheckout = () => {
    history.push('/checkout');
  }

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setAnchorState({ ...anchorState, [anchor]: open });
      };

  React.useEffect(() => {
    let width = window.innerWidth;
    if (width <= 768) {
      setDrawerWidth(width);
    }
  }, []);

  React.useEffect(() => {
    setSelectedTheme(theme);
   }, [theme]);

  const roundToTwoDecimals = (num: number ) => {
    return Math.round(num*100)/100;
  }


  const CheckEmpty = () => {
    if (shoppingList.length > 0) {
      return (
        <>
          <List sx={{ pb: '200px', pt: '100px', backgroundColor: selectedTheme.colors.body, color: selectedTheme.colors.text}}>
          {/*<button onClick={shoopingFunctions.removeDiscounts}>Remove Discounts</button>*/}
            {shoppingList.map((item, index) => (
              <Box key={ index } sx={{ width: `${drawerWidth}px` }}>
                <ListItem key={`item-${index}`}>
                  <Grid sx={{ display: 'flex', flexDirection: 'row', justifyItems: 'space-around', width: 400 }}>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <img alt="Experience banner" style={{ width: '75px', height: '100px' }} src={item.bannerImage} />
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                      <ListItemText disableTypography primary={<Typography variant="body2" sx={{ color: 'gray' }}>{item.city}</Typography>} />
                      <ListItemText primary={item.title} />
                      <ListItemText disableTypography primary={<Typography variant="body2" sx={{ color: 'gray' }}>{item.ownerName}</Typography>} />
                      {
                        !item.discount ?
                          <ListItemText  primary={item.price! !== 0 ? item.price! % 1 === 0 ? `$ ${item.price}.00 USD` : `$ ${item.price} USD` : `Free`} />
                        :
                        <>
                          <ListItemText sx={{ textDecoration: "line-through"}} primary={item.price! !== 0 ? item.price! % 1 === 0 ? `$ ${item.price}.00 USD` : `$ ${item.price} USD` : `Free`} />
                          <ListItemText 
                            sx={{ color: "#f00" }} 
                            primary={
                              item.price! !== 0 ? 
                               item?.price && ( item.price! % 1 === 0  ? 
                                `$ ${item.price * ( 100 - item.discount?.discount ) / 100}.00 USD` 
                                : `$ ${roundToTwoDecimals( item.price && item.price * ( 100 - item.discount?.discount) / 100 )} USD` )
                              : `Free`} />
                        </>
                      }
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                      <IconButton
                        onClick={() => removeThisItem(item.id)}
                        sx={{ color: '#000' }}
                      >
                        <Delete sx={{ fontSize: 30 }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />

              </Box>
            ))}
          </List>
          <Paper elevation={6} className='shopping-footer'>
            <Grid container sx={{ display: 'flex', flexDirection: 'column', width: `${drawerWidth}px`, backgroundColor: selectedTheme.colors.body,
          color: selectedTheme.colors.text }}>
              <Grid sx={{ display: 'flex', flexDirection: 'row', mx: '20px' }}>
                <ListItemText disableTypography primary={<Typography variant="h6">Subtotal: </Typography>} />
                <ListItemText disableTypography primary={<Typography variant="h6" sx={{ textAlign: 'end' }}>
                  {shoopingFunctions.totalPrice! !== 0 ?
                  shoopingFunctions.totalOrig! % 1 === 0
                    ? `$ ${shoopingFunctions.totalOriginal}.00 USD`
                    : `$ ${shoopingFunctions.totalOriginal} USD`
                  : `Free`}</Typography>} />
              </Grid>
              <Grid sx={{ display: 'flex', flexDirection: 'row', mx: '20px' }}>
                <ListItemText disableTypography primary={<Typography variant="h5">Total: </Typography>} />
                <ListItemText disableTypography primary={<Typography variant="h5" sx={{ textAlign: 'end' }}>
                  { totalWithDiscount !== 0 ?
                    totalWithDiscount % 1 === 0
                    ? `$ ${totalWithDiscount}.00 USD`
                    : `$ ${roundToTwoDecimals(totalWithDiscount)} USD`
                  : `Free`}</Typography>} />
              </Grid>
              <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                <Button sx={{
                  backgroundColor: selectedTheme.colors.button.background,
                  borderRadius: "20px",
                  color: selectedTheme.colors.button.text,
                  px: 5,
                  width: 250,
                  fontFamily: selectedTheme.font,
                  boxShadow: 'none',
                  textTransform: 'none',
                  fontSize: "20px",
                  lineHeight: "25px",
                  fontWeight: 'bold',
                  mb: 2, '&:hover': {
                    bgcolor: '#d6627e',
                  },
                }}
                  onClick={() => toCheckout()}

                >Proceed to Checkout</Button>
              </Grid>
            </Grid>
          </Paper>
        </>
      );

    } else {
      return (
        <Grid sx={{
          display: 'flex', height: `${window.innerHeight}px`,
          justifyContent: 'center', alignItems: 'center', width: `${drawerWidth}px`
        }}>
          <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ShoppingCartOutlined sx={{ fontSize: 100 }} />
            <Typography variant='h4'>
              Your cart is empty.
            </Typography>
          </Grid>
        </Grid>
      );
    }
  }

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : `${drawerWidth}px` }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Grid container>
        <Grid sx={{
          display: 'flex', flexDirection: 'column', position: 'fixed', zIndex: 1,
          backgroundColor: selectedTheme.colors.body, width: `${drawerWidth}px`, paddingBottom: 1
        }}>
          <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton
              onClick={toggleDrawer('right', false)}
              sx={{ color: selectedTheme.colors.text }}
            >
              <Close sx={{ fontSize: 30 }} />
            </IconButton>
          </Grid>
          <Grid sx={{ display: 'flex', flexDirection: 'row', ml: '25px' }}>
            <Typography variant='h5' sx={{color: selectedTheme.colors.text}}>
              Shopping Cart
              </Typography>
          </Grid>
        </Grid>
        <Divider />
        <CheckEmpty />
      </Grid>

    </Box>
  );

  return (
    <Grid container>
      <IconButton
        id="demo-positioned-button"
        onClick={toggleDrawer('right', true)}
        size="small"
        sx={{ color: selectedTheme.colors.primary, marginTop: "3px" }}
      >
        <Badge badgeContent={shoppingList.length} color="error">
          <ShoppingCartOutlined sx={{ fontSize: 40}} />
        </Badge>

      </IconButton>
      <Drawer
        anchor="right"
        open={anchorState['right']}
        onClose={toggleDrawer('right', false)}
      >
        {list('right')}
      </Drawer>

    </Grid>
  );
};

const Navbar: React.FC<INavbar> = ({ currentPage }) => {

  const [drawerWidth, setDrawerWidth] = React.useState<Number>(400);
  const [anchorState, setAnchorState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const {theme, themeName, switchTheme} = useTheme();
  const [selectedTheme, setSelectedTheme] = React.useState<any>(theme);
  const isChecked = themeName === "Dark" ? true : false;

  React.useEffect(() => {
    setSelectedTheme(theme);
   }, [theme]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setAnchorState({ ...anchorState, [anchor]: open });
      };

  React.useEffect(() => {
    let width = window.innerWidth;
    if (width <= 768) {
      setDrawerWidth(width);
    }
  }, []);

  const history = useHistory();

  const changeView = (page: string) => {
    switch (page) {
      case "Home":
        window.open('https://playeatlas.com/');
        break;
      case "Adventures":
        history.push(`/`);
        break;
      case "Partners":
        window.open('https://playeatlas.com/partners');
        break;
      case "Build a Tour":
        window.open('https://playeatlas.com/builders');
        break;
      case "FAQs":
        window.open('https://playeatlas.com/faq');
        break;
      case "Blog":
        window.open('https://playeatlas.com/blog');
        break;
      case "Authors":
        window.open('https://playeatlas.com/blog');
        break;
      case "Media":
        window.open('https://playeatlas.com/blog');
        break;
    }

  }

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AppBar position="fixed" sx={{ backgroundColor: selectedTheme.colors.body, }}  >
        <Toolbar>
          <div className="container">
            <Grid item className="menu-icon">
              <IconButton onClick={toggleDrawer("left", true)}>
                <MenuIcon sx={{ fontSize: 30, color: selectedTheme.colors.text }} />
              </IconButton>
              <Drawer
                anchor="left"
                open={anchorState['left']}
                onClose={toggleDrawer('left', false)}
              >
                <Box
                  sx={{ width: `${drawerWidth}px`, backgroundColor: selectedTheme.colors.body, color: selectedTheme.colors.text, height: '100%' }}
                  role="presentation"
                  onKeyDown={toggleDrawer("left", false)}
                >
                  <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid>
                      <IconButton
                        onClick={toggleDrawer('left', false)}
                        sx={{ color: selectedTheme.colors.text }}
                      >
                        <Close sx={{ fontSize: 30 }} />
                      </IconButton>
                    </Grid>
                    <Grid sx={{ width: `${drawerWidth}px`, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                      {pages.map((page, index) => {
                        if (page === currentPage) {
                          return (
                            <Button
                              key={`${index}-${page}`}
                              sx={{
                                my: 2,
                                mx: 2,
                                width: '90%',
                                color: '#3a3a3a',
                                display: 'block',
                                borderBottom: `4px solid ${selectedTheme.colors.primary}`,
                                paddingBottom: '3px',
                                fontFamily: selectedTheme.font,
                                boxShadow: 'none',
                                textTransform: 'none',
                                fontSize: "20px"
                              }}
                            >
                              {page}
                            </Button>
                          );
                        } else {
                          return (
                            <Button
                            key={`${index}-${page}`}
                              className={'inactive'}
                              onClick={() => changeView(page)}
                              sx={{
                                my: 2,
                                width: '90%',
                                color: '#3a3a3a',
                                display: 'block',
                                fontFamily: selectedTheme.font,
                                boxShadow: 'none',
                                textTransform: 'none',
                                fontSize: "20px"
                              }}
                            >
                              {page}
                            </Button>
                          );
                        }
                      })}
                    </Grid>
                  </Grid>

                </Box>
              </Drawer>
            </Grid>
            <Grid item>
              <Box
                className="logo"
                component="img"
                src="https://playeatlas.com/assets/logo-black-text.png"

              ></Box>
            </Grid>
            <Grid item>
              <Grid sx={{display: 'flex', flexDirection: 'row'}}>
              <Box sx={{  display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => {
                  if (page === currentPage) {
                    return (
                      <Button
                      key={`a-${page}`}
                        sx={{
                          my: "0.5rem",
                          mx: "0.5rem",
                          color: '#3a3a3a',
                          display: 'block',
                          borderBottom: `4px solid ${selectedTheme.colors.primary}`,
                          paddingBottom: '3px',
                          fontFamily: selectedTheme.font,
                          boxShadow: 'none',
                          textTransform: 'none',
                          fontSize: "1.1rem"
                        }}
                      >
                        {page}
                      </Button>
                    );
                  } else {
                    return (
                      <Button
                        key={page}
                        className={'inactive underline'}
                        onClick={() => changeView(page)}
                        sx={{
                          my: "0.5rem",
                          mx: "0.5rem",
                          color: '#3a3a3a',
                          display: 'block',
                          fontFamily: selectedTheme.font,
                          boxShadow: 'none',
                          textTransform: 'none',
                          fontSize: "1.1rem"
                        }}
                      >
                        {page}
                      </Button>
                    );
                  }
                })}
              </Box>
            
            <Grid sx={{display: 'flex', flexDirection: 'row'}}>
                <FormGroup>
                  {/*<MaterialUISwitch onClick={switchTheme} checked={isChecked}/>*/}
                </FormGroup>
            </Grid>
            <Grid >
              <Box >
                <IconMenu></IconMenu>
              </Box>
            </Grid>
            </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Navbar;

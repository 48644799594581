import { log } from 'console';
import React, {  useContext, useState, useEffect } from 'react';


// interface ShopItem {
//     name: string,
//     id: string,
//     price: number,
//     quantity: number,
//     image: string,
//     description: string,
//     category: string,
// }

interface Experience {
	bannerImage?:string, 
	title?: string,
    city?:string,
    shortDescription?: string,
    theme?: string,
    price?: number,
    id: string,
    author: string,
    ownerName: string,
    discount?: {
        code: string,
        discount: number
    }
}


const ShoppingContext = React.createContext<Experience[]>([]);
// const ShoppingContext = React.createContext<number>(0);
const ShoppingUpdateContext = React.createContext<any>([]);

export function useShopping(){
    return useContext(ShoppingContext);
}

export function useShoppingUpdate(){
    return useContext(ShoppingUpdateContext);
}


export function ShoppingProvider({children}: any){

    const [shoppingList, setShoppingList] = useState<Experience[]>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalOriginal, setTotalOriginal] = useState<number>(0);
    const [totalDiscount, setTotalDiscount] = useState<number>(0);
    const [discountValidCode, setDiscountValidCode] = useState<string>("")

    const updateTotalPrice = () => {
        let total = 0;
        shoppingList.forEach((item: any) => {
            if(item.discount) {
                total += item.price - item.price*item.discount.discount/100;
            }else{
                total += item.price;
            }
        });
        setTotalPrice(total);
    }

    const updateTotalDiscount = () => {
        let total = 0;
        shoppingList.forEach((item: any) => {
            if(item.discount) {
                total += item.price*item.discount.discount/100;
            }
        });
        setTotalDiscount(total);
    }

    const updateTotalOriginal = () => {
        let total = 0;
        shoppingList.forEach((item: any) => {
            total += item.price;
        });
        setTotalOriginal(total);
    }

    const addItem = (item: any): any => {
        setShoppingList([...shoppingList, item]);
    }

    const removeItem = (id: string) =>{
        setShoppingList(shoppingList.filter(item => item.id !== id));
    }

    const updateItem = (id: string, code: string, discount: number) =>{
        let itemIndex = shoppingList.findIndex(item => item.id === id);
        let aux = [...shoppingList];
        aux[itemIndex] = {...shoppingList[itemIndex], discount: {
            code: code,
            discount: discount
        }} 
        setShoppingList(aux);
    }

    const removeDiscounts = () => {
        const y = shoppingList.map((item) => {
            if(item.discount)
                delete item.discount;
            return item;
        })
        setDiscountValidCode("")
        setShoppingList(y)
        setTotalDiscount(0)
    }

    const removeDiscount = (id: string, code: string) => {
        let itemIndex = shoppingList.findIndex(item => item.id === id);
        let aux = [...shoppingList];
        delete aux[itemIndex].discount;
        setShoppingList(aux);
        setDiscountValidCode("")
    }

    const removeAll = () =>{
        setShoppingList([]);
        setTotalPrice(0);
    }

    const saveToLocalStorage = () => {
        localStorage.setItem('shoppingList', JSON.stringify(shoppingList));
    }

    const getLocalStorage = ()=>{
        const localStorageItem = localStorage.getItem('shoppingList');
        if(localStorageItem){
            setShoppingList(JSON.parse(localStorageItem));
        }
    }

    useEffect(() => {
        getLocalStorage();
    }, []);

    useEffect(() => {
        saveToLocalStorage();
        updateTotalPrice();
        updateTotalDiscount();
        updateTotalOriginal();
        console.log("list ", shoppingList);
    }, [shoppingList]);


    return (
        <ShoppingContext.Provider value={shoppingList}>
            <ShoppingUpdateContext.Provider value={{addItem: addItem, removeItem: removeItem, totalPrice: totalPrice, removeAll: removeAll, updateItem: updateItem, removeDiscounts: removeDiscounts, removeDiscount: removeDiscount, totalDiscount: totalDiscount, totalOriginal: totalOriginal, setDiscountValidCode: setDiscountValidCode, discountValidCode: discountValidCode}}>
                {children}
            </ShoppingUpdateContext.Provider>
         </ShoppingContext.Provider>
    )
}                                       

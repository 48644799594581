import React, { useState, useEffect, useRef, useCallback } from 'react'
import firebase from "../firebase/firebase"
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ExperienceCard from './ExperienceCard';
import '../css/experience.css';
import CircularProgress from '@mui/material/CircularProgress';
import Search from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Apartment from '@mui/icons-material/Apartment';
import { useTheme } from '../theme/useTheme';
import { setToLS, getFromLS } from '../utils/storage';
import { getUserLocation, getDistanceTwoCoordinates } from '../utils/LocationUtils';
import { userIsInIllionis } from '../utils/MapboxUtils';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearcherTours from './SearcherTours';
import ChipInput from './ChipInput';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { AnyNsRecord } from 'dns';
import OutlinedInput from '@mui/material/OutlinedInput';
import { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const color = "red";
const useStyles = makeStyles(() => ({
    select: {
        "&:before": {
            borderColor: color
        },
        "&:after": {
            borderColor: color
        }
    },
    icon: {
        fill: color
    }
}));

const HomeExperienceCards = () => {

    const classes = useStyles();
    const [experiences, setExperiences] = useState<any[]>([])
    const experiencesWithoutFilters = useRef<any[]>([])

    const [loading, setLoading] = useState(false);
    const { theme, themeLoaded, getFonts } = useTheme();
    const [selectedTheme, setSelectedTheme] = React.useState<any>(theme);
    const [openModalLocation, setOpenModalLocation] = useState(false);
    const [showMessageIllinois, setShowMessageIllinois] = useState(false);
    const [showMessageActivateGelocation, setShowMessageActivateGelocation] = useState(false);
    const [listGuides, setListGuides] = useState<string[]>([]);
    const [listLocations, setListLocations] = useState<string[]>([]);
    const categorieSelected = useRef<string[]>([]);
    const [categoriesSelected, setCategoriesSelected] = useState<string[]>([])
    const sortSelected = useRef<string>('');
    const guideSelected = useRef<string[]>([]);
    const [guidesSelected, setGuidesSelected] = useState<string[]>([])
    const citySelected = useRef<string[]>([]);
    const [citiesSelected, setCitiesSelected] = useState<string[]>([])
    const defaultValueForCities = useRef([]);
    const firstRender = useRef<boolean>(true)
    const keywordsChipSelected = useRef<string[]>([]);
    const [keywordsChipToShow, setKeywordsChipToShow] = useState<string[]>([]);
    const [widthScreen, setWidthScreen] = useState<number>(0);
    const [uiLoaded, setUiLoaded] = useState(false);
    const [showResetAutocomplete, setShowResetAutocomplete] = useState(0);

    if (firstRender.current) {   // The default values can't be changing, and they must be available in the first render
        defaultValueForCities.current = getFromLS('city') || [];
        firstRender.current = false;
    }


    const handleCloseModalLocation = () => {
        setOpenModalLocation(false);
    }


    React.useEffect(() => {
        setSelectedTheme(theme);
    }, [theme]);


    const checkAndHandleAllDropdowns = (dropDownModified: string, value: any, data: any[] = []) => {

        removeErrorsMessages();

        let dataFilteredAndSorted: any[] = experiencesWithoutFilters.current.slice();

        if (dropDownModified === 'categories') {
            if (value.length) {
                let dataWithDuplicates: any[] = []

                for (const tour of dataFilteredAndSorted) {
                    for (const category of value) {
                        if (tour.details.type === category) {
                            dataWithDuplicates.push(tour);
                        }
                    }
                }
                dataFilteredAndSorted = dataWithDuplicates;
            }
        }
        else {
            if (categorieSelected.current.length > 0) {
                let dataWithDuplicates: any[] = []

                for (const tour of dataFilteredAndSorted) {
                    for (const category of categorieSelected.current) {
                        if (tour.details.type === category) {
                            dataWithDuplicates.push(tour);
                            break;
                        }
                    }
                }
                dataFilteredAndSorted = dataWithDuplicates;
            }
        }


        if (dropDownModified === 'guide') {
            if (value.length) {
                let dataWithDuplicates: any[] = []

                for (const tour of dataFilteredAndSorted) {
                    for (const guide of value) {
                        if (tour.details.ownerName === guide) {
                            dataWithDuplicates.push(tour);
                        }
                    }
                }
                dataFilteredAndSorted = dataWithDuplicates;
            }
        }
        else {
            if (guideSelected.current.length > 0) {
                let dataWithDuplicates: any[] = []

                for (const tour of dataFilteredAndSorted) {
                    for (const guide of guideSelected.current) {
                        if (tour.details.ownerName === guide) {
                            dataWithDuplicates.push(tour);
                            break;
                        }
                    }
                }
                dataFilteredAndSorted = dataWithDuplicates;
            }
        }


        if (dropDownModified === 'city') {
            if (value.length) {
                let dataWithDuplicates: any[] = []

                for (const tour of dataFilteredAndSorted) {
                    for (const city of value) {
                        if (tour.address.city.toLowerCase() === city.toLowerCase()) {
                            dataWithDuplicates.push(tour);
                        }
                    }
                }
                dataFilteredAndSorted = dataWithDuplicates;
            }
        }
        else {
            if (citySelected.current.length > 0) {
                let dataWithDuplicates: any[] = []

                for (const tour of dataFilteredAndSorted) {
                    for (const city of citySelected.current) {
                        if (tour.address.city.toLowerCase() === city.toLowerCase()) {
                            dataWithDuplicates.push(tour);
                        }
                    }
                }
                dataFilteredAndSorted = dataWithDuplicates;
            }
        }


        if (dropDownModified === 'keywordsChip') {
            if (value.length > 0) {
                const auxArray: any[] = []

                for (const tour of dataFilteredAndSorted) {
                    const hasAllWords = value.every((wordToSearch: string) => {
                        return tour.title.toLowerCase().indexOf(wordToSearch) > -1
                            || tour.theme.toLowerCase().indexOf(wordToSearch) > -1
                    });
                    if (hasAllWords)
                        auxArray.push(tour);
                }


                dataFilteredAndSorted = auxArray;
                // remove duplicates
                dataFilteredAndSorted = dataFilteredAndSorted.filter((tour, indexTour, self) => (
                    indexTour === self.findIndex((t) => t.title === tour.title)
                ))
            }
        }
        else {
            if (keywordsChipSelected?.current?.length > 0) {
                const auxArray: any[] = []

                for (const tour of dataFilteredAndSorted) {
                    const hasAllWords = keywordsChipSelected.current.every((wordToSearch: string) => {
                        return tour.title.toLowerCase().indexOf(wordToSearch) > -1
                            || tour.theme.toLowerCase().indexOf(wordToSearch) > -1
                    });
                    if (hasAllWords)
                        auxArray.push(tour);
                }
                dataFilteredAndSorted = auxArray;
                // remove duplicates
                dataFilteredAndSorted = dataFilteredAndSorted.filter((tour, indexTour, self) => (
                    indexTour === self.findIndex((t) => t.title === tour.title)
                ))
            }
        }


        if (dropDownModified === 'sort') {
            if (value === 'price') {
                setExperiences(dataFilteredAndSorted.sort(comparePrice));
            } else if (value === 'duration') {
                setExperiences(dataFilteredAndSorted.sort(compareDuration));
            } else if (value === 'rating') {
                setExperiences(dataFilteredAndSorted.sort(compareRating));
            } else if (value === 'guide') {
                setExperiences(dataFilteredAndSorted.sort(compareGuide));
            } else if (value === 'theme') {
                setExperiences(dataFilteredAndSorted.sort(compareTheme));
            } else if (value === 'location') {
                setExperiences(dataFilteredAndSorted.sort(compareByLocation));
            } else {
                setExperiences(dataFilteredAndSorted.sort(compareCreated))
            }
        }
        else {
            if (sortSelected.current) {
                if (sortSelected.current === 'price') {
                    setExperiences(dataFilteredAndSorted.sort(comparePrice));
                } else if (sortSelected.current === 'duration') {
                    setExperiences(dataFilteredAndSorted.sort(compareDuration));
                } else if (sortSelected.current === 'rating') {
                    setExperiences(dataFilteredAndSorted.sort(compareRating));
                } else if (sortSelected.current === 'guide') {
                    setExperiences(dataFilteredAndSorted.sort(compareGuide));
                } else if (sortSelected.current === 'theme') {
                    setExperiences(dataFilteredAndSorted.sort(compareTheme));
                } else if (sortSelected.current === 'location') {
                    setExperiences(dataFilteredAndSorted.sort(compareByLocation));
                } else {
                    setExperiences(dataFilteredAndSorted.sort(compareCreated));
                }
            }
        }

        setExperiences(dataFilteredAndSorted);
    }


    const handleCategories = (event: any) => {
        let valueDropdown: string[] = event.target.value;

        setLoading(true);

        if (valueDropdown.includes("")) {
            setToLS('category', []);
            categorieSelected.current = [];
            setCategoriesSelected([]);
            checkAndHandleAllDropdowns("categories", []);
        } else {
            setToLS('category', categorieSelected.current);
            categorieSelected.current = valueDropdown;
            setCategoriesSelected(valueDropdown);
            checkAndHandleAllDropdowns("categories", valueDropdown);
        }

        setShowMessageIllinois(false);
        setLoading(false);
    };



    const handleSort = (event: any) => {
        let valueDropdown: string = event.target.value

        setLoading(true);
        sortSelected.current = valueDropdown;
        checkAndHandleAllDropdowns("sort", valueDropdown);

        setShowMessageIllinois(false);
        if (valueDropdown === "") {
            setToLS('sort', "");
        } else {
            setToLS('sort', valueDropdown);
        }
        setLoading(false);
    };



    const handleSortByGuide = (event: any) => {
        let valueDropdown: string[] = event.target.value;

        setLoading(true);

        if (valueDropdown.includes("")) {
            setToLS('guide', []);
            guideSelected.current = [];
            setGuidesSelected([]);
            checkAndHandleAllDropdowns("guide", []);
        } else {
            setToLS('guide', guideSelected.current);
            guideSelected.current = valueDropdown;
            setGuidesSelected(valueDropdown);
            checkAndHandleAllDropdowns("guide", valueDropdown);
        }

        setShowMessageIllinois(false);
        setLoading(false);
    }


    const handleSortByCity = (value: string[]) => {
        let valueDropdown: string[] = value;
        setCitiesSelected(valueDropdown)

        setLoading(true);

        citySelected.current = valueDropdown;
        setCitiesSelected(valueDropdown);

        if (valueDropdown.includes("")) {
            setToLS('city', []);
            checkAndHandleAllDropdowns("city", []);
        } else {
            setToLS('city', citySelected.current);
            checkAndHandleAllDropdowns("city", valueDropdown);
        }

        setShowMessageIllinois(false);
        setLoading(false);

    }


    const handleSortByKeywordChip = (data: { value: string, addKeyWord: boolean, indexWord: number }) => {
        const { value, addKeyWord, indexWord } = data;

        setLoading(true);
        if (addKeyWord) {
            if (addKeyWord)
                keywordsChipSelected.current.push(value);
        }
        else
            keywordsChipSelected.current.splice(indexWord, 1);

        setKeywordsChipToShow(keywordsChipSelected.current);
        checkAndHandleAllDropdowns("keywordsChip", keywordsChipSelected.current);

        if (showMessageIllinois)
            setShowMessageIllinois(false);

        setToLS('keywordsChip', JSON.stringify(keywordsChipSelected.current));
        setLoading(false);
    }

    const removeErrorsMessages = () => {
        if (showMessageActivateGelocation)
            setShowMessageActivateGelocation(false);
        if (showMessageIllinois)
            setShowMessageIllinois(false);
    }

    const resetDropDowns_And_CleanTheirLocalStorageData = () => {
        categorieSelected.current = [];
        setCategoriesSelected([])
        sortSelected.current = '';
        guideSelected.current = [];
        setGuidesSelected([]);
        citySelected.current = [];
        setCitiesSelected([]);
        keywordsChipSelected.current = [];
        setKeywordsChipToShow([]);
        setShowResetAutocomplete(showResetAutocomplete + 1);
        setToLS('category', []);
        setToLS('sort', false);
        setToLS('guide', []);
        setToLS('city', []);
        setToLS('keywordsChip', []);
        removeErrorsMessages();
    }


    const clearFilters = () => {

        removeErrorsMessages();
        resetDropDowns_And_CleanTheirLocalStorageData();
        setLoading(true);
        setExperiences(experiencesWithoutFilters.current.slice())
        setLoading(false);
    };


    useEffect(() => {
        setLoading(true);
        getExperiences()
            .then((data: any) => {
                setExperiences(data.sort(compareCreated));
                experiencesWithoutFilters.current = data;

                // getting data from localStorage
                categorieSelected.current = getFromLS('category') || [];
                setCategoriesSelected(categorieSelected.current)
                sortSelected.current = getFromLS('sort');
                guideSelected.current = getFromLS('guide') || [];
                setGuidesSelected(guideSelected.current)
                citySelected.current = getFromLS('city') || [];
                setCitiesSelected(citySelected.current);

                if (getFromLS('keywordsChip')?.length > 0) {
                    keywordsChipSelected.current = JSON.parse(getFromLS('keywordsChip'))
                    setKeywordsChipToShow(keywordsChipSelected.current)
                }

                // getting guides
                const guides: string[] = data.map((tour: any) => tour.details.ownerName);
                const uniqueGuides: string[] = guides.filter((guide, index, self) => {
                    return self.indexOf(guide) === index;
                })
                setListGuides(uniqueGuides);

                const all_locations: string[] = data.map((tour: any) => tour.address.city);
                const uniqueLocations: any[] = all_locations.filter((location: any, index: number, self: any) => {
                    return self.indexOf(location) === index;
                })

                setListLocations(uniqueLocations.sort());

                if (!categorieSelected.current.length && !sortSelected.current && !guideSelected.current.length
                    && !citySelected.current.length && !keywordsChipSelected?.current?.length) {
                    setExperiences(data.sort(compareCreated))
                }
                else {
                    checkAndHandleAllDropdowns("", "", data);
                }
            }).finally(() => { setLoading(false) });
    }, [])



    const getExperiences = async (): Promise<any> => {
        const db = firebase.firestore();
        // You can get the collectionName and documentName from the shortcode attribute
        let data: any[] = []
        const snapshot = await db.collection('events').get();
        snapshot.docs.forEach(async (doc) => {
            if (doc.data().details) {
                if (doc.data().details.status === 'published') {
                    if (!doc.data().isDeleted) {
                        data.push(doc.data())
                    }
                }
            }
        })

        return data;
    }


    const getExperiencesByLocation = async () => {
        try {

            const eAtlasHasGeoLocationPermission = await geoLocationPermissionState();
            if (eAtlasHasGeoLocationPermission === 'granted') {
                // latitude // longitude
                const userCoordinates: [number, number] = await getUserLocation();

                //localStorage.setItem('userPermission', 'true');
                // your current position                                   // latitude       // longitude
                //const userIsInIllionoisCurrently = await userIsInIllionis(userCoordinates[0], userCoordinates[1]);

                //                                              latitude      longitude
                // quincy position      (left of illinois)      [39.935602, -91.409873]
                // const userCoordinates: [number, number] = [39.935602, -91.409873]

                // danville position    (right of illinois)     [40.125222, -87.630461]
                // const userCoordinates: [number, number] = [40.125222, -87.630461]

                // Metropolis position  (bottom of illinois)    [37.151166, -88.731998]
                // const userCoordinates: [number, number] = [37.151166, -88.731998]

                // Rockford position    (top of illinois)       [42.271395, -89.093966]
                // const userCoordinates: [number, number] = [42.271395, -89.093966]

                // position in chicago  (top right of illinois) [41.875562, -87.624421]
                // const userCoordinates: [number, number] = [41.875562, -87.624421]

                // center of chicago [41.8781136, -87.6297982]
                //const userCoordinates: [number, number] = [41.881832, -87.623177]

                //const userIsInIllionoisCurrently = await userIsInIllionis(39.935602, -91.409873);
                //console.log("userCoordinates", userCoordinates);
                
                //if (userIsInIllionoisCurrently.ok) {
                // replace userCoordinates with some of the above arrays for testing
                removeErrorsMessages();
                resetDropDowns_And_CleanTheirLocalStorageData();
                setExperiences(orderToursByProximity(userCoordinates, experiencesWithoutFilters.current.slice()));
                return;
                //}
            }
            else {
                alert("you don't have permission")
            }

        } catch (error) {
            console.log('error', error);
            if (error?.msg) {
                if (error?.isntIllinois) {
                    setShowMessageIllinois(true)
                }
            }
            else {
                setShowMessageActivateGelocation(true);
            }
        }
    }


    const geoLocationPermissionState = async (): Promise<string> => {

        return new Promise((resolve, reject) => {
            navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
                if (result.state === 'granted') {
                    resolve('granted');
                }
                else if (result.state === 'prompt') {
                    resolve('prompt');
                }
                else {
                    resolve('denied')
                }
            });
        })
    }

    const checkGeoLocationPermission = async () => {

        try {
            const permissionState = await geoLocationPermissionState();
            switch (permissionState) {
                case "granted":
                    getExperiencesByLocation();
                    break;
                case "prompt":
                    setOpenModalLocation(true);
                    break;
                case "denied":
                    setShowMessageActivateGelocation(true);
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.log('error', error);
        }
    }


    const categories = [
        { label: "All", value: "" },
        { label: "Scavenger Hunts", value: "treasure_hunt" },
        { label: "Guided Tours", value: "guided_tour" },
        { label: "Food Adventures", value: "food_experience" },
        { label: "Date Nights", value: "date_night" },
        { label: "Drink Adventures", value: "drink_tour" },
    ];


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 1
        }}>
            <Dialog
                open={openModalLocation}
                onClose={handleCloseModalLocation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Let us improve your experience by accessing your location.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModalLocation}>Disagree</Button>
                    <Button onClick={async () => {
                        handleCloseModalLocation();
                        await getUserLocation(); // this line launch the alert to ask for the permission
                        getExperiencesByLocation();
                    }} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>

            <div className="container-chip-location-inputs">
                <ChipInput
                    wordsList={keywordsChipToShow}
                    inputName="Adventure name or keywords"
                    onAdd={(chipWord: string) => {
                        handleSortByKeywordChip({ value: chipWord.toLowerCase(), addKeyWord: true, indexWord: 0 })
                    }}
                    onDelete={(chipWord: string, index: number) => {
                        handleSortByKeywordChip({ value: chipWord.toLowerCase(), addKeyWord: false, indexWord: index })
                    }}
                />
                <Autocomplete
                    className='autocomplete'
                    multiple
                    id="locations_dropdown"
                    sx={{ backgroundColor: "ghostwhite" }}
                    options={listLocations}
                    limitTags={2}
                    //getOptionLabel={(option) => option.title
                    defaultValue={defaultValueForCities.current}
                    filterSelectedOptions={false}
                    disableCloseOnSelect
                    key={showResetAutocomplete}
                    renderTags={(value: readonly string[], getTagProps) =>
                        citiesSelected.map((option: string, index: number) => {
                            return (
                                <Chip variant="outlined" id={`chip-${index}`} key={index} label={option} sx={{ mr: 1, mt: 1 }} />
                            )
                        })
                    }
                    //renderTags={(selected: any) => selected.join(', ')}
                    onChange={(event: any, newValue: any) => {
                        handleSortByCity(newValue)
                    }}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="City"
                            sx={{
                                pb: 2
                            }}
                        />
                    )}
                />
                <Button sx={{
                    fontFamily: selectedTheme.font,
                    mt: [1, 0],
                    width: "200px",
                    position: "absolute",
                    left: 965,
                    display: ["none","none","block"],
                    top: 5,
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    textTransform: "capitalize",
                    bgcolor: selectedTheme.colors.button.background,
                    color: 'white',
                    '&:hover': {
                        bgcolor: selectedTheme.colors.button.hover,
                    },
                }}
                    onClick={checkGeoLocationPermission}
                >
                    Nearby Adventures
                </Button>
            </div>
            <Box className="filters">

                <FormControl
                    sx={{
                        width: ["100%", "300px"],
                        mr: 2,
                        fontFamily: selectedTheme.font,

                    }}>
                    <InputLabel id="categories-label" sx={{ fontFamily: selectedTheme.font, color: selectedTheme.colors.text }}>Categories</InputLabel>
                    <Select
                        labelId="categories-label"
                        id="categories-select"
                        //value={categorieSelected?.current?.length > 0 ? categorieSelected?.current : []} // this avoids warnings about uncrontrolled components
                        value={categoriesSelected}
                        label="Categories"
                        multiple
                        renderValue={(selected) => {
                            const categoriesObj = categories.filter(category => selected.includes(category.value))
                            const labels = categoriesObj.map(category => category.label);
                            return labels.join(', ')
                        }}
                        onChange={handleCategories}
                        sx={{
                            fontFamily: selectedTheme.font,
                            border: `${selectedTheme.name === "Dark" ? `1px solid ${selectedTheme.colors.text}` : '0px'}`,
                            color: selectedTheme.colors.text
                        }}
                    >
                        {
                            categories.map((category, index) => {

                                return (
                                    <MenuItem key={index} value={category.value}>
                                        <Checkbox checked={
                                            categoriesSelected.length === 0 ?
                                                (category.label === "All" ? true : false)
                                                : categoriesSelected.indexOf(category.value) > -1
                                        } />
                                        <ListItemText primary={category.label} />
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>

                <FormControl sx={{ width: ["100%", "300px"], mr: 2, mt: [1, 0] }}>
                    <InputLabel id="sort-label-guide" sx={{ fontFamily: selectedTheme.font, color: selectedTheme.colors.text }}>Whoa!Guides</InputLabel>
                    <Select
                        labelId="sort-label-guide"
                        id="sort-guide-select"
                        value={guidesSelected}
                        label="Whoa!Guides"
                        multiple
                        onChange={handleSortByGuide}
                        renderValue={(selected) => selected.join(', ')}
                        sx={{
                            fontFamily: selectedTheme.font,
                            border: `${selectedTheme.name === "Dark" ? `1px solid ${selectedTheme.colors.text}` : '0px'}`,
                            color: selectedTheme.colors.text
                        }}
                    >
                        <MenuItem key={"none"} value={""}>
                            <Checkbox checked={guidesSelected.length === 0} />
                            <ListItemText primary={"All"} />
                        </MenuItem>
                        {
                            listGuides.map((guide, index) => {
                                return (
                                    <MenuItem key={index} value={guide}>
                                        <Checkbox checked={guidesSelected.indexOf(guide) > -1} />
                                        <ListItemText primary={guide} />
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl sx={{ width: ["100%", "300px"], mr: 4, mt: [1, 0] }}>
                    <InputLabel id="sort-label" sx={{ fontFamily: selectedTheme.font, color: selectedTheme.colors.text }}>Sort By</InputLabel>
                    <Select
                        labelId="sort-label"
                        id="sort-select"
                        value={sortSelected?.current ? sortSelected?.current : ""}
                        label="Sort By"
                        onChange={handleSort}
                        sx={{
                            fontFamily: selectedTheme.font,
                            border: `${selectedTheme.name === "Dark" ? `1px solid ${selectedTheme.colors.text}` : '0px'}`,
                            color: selectedTheme.colors.text
                        }}
                    >
                        <MenuItem sx={{ fontFamily: selectedTheme.font }} key={'none'} value=""><em>None</em></MenuItem>
                        <MenuItem sx={{ fontFamily: selectedTheme.font }} key="price" value="price">Price</MenuItem>
                        <MenuItem sx={{ fontFamily: selectedTheme.font }} key="duration" value="duration">Duration</MenuItem>
                        <MenuItem sx={{ fontFamily: selectedTheme.font }} key="Guide" value="guide">Guide</MenuItem>
                        <MenuItem sx={{ fontFamily: selectedTheme.font }} key="Rating" value="rating">Rating</MenuItem>
                        <MenuItem sx={{ fontFamily: selectedTheme.font }} key="Theme" value="theme">Theme</MenuItem>
                        <MenuItem sx={{ fontFamily: selectedTheme.font }} key="Location" value="location">Location</MenuItem>
                    </Select>
                </FormControl>
                <Button sx={{
                    fontFamily: selectedTheme.font,
                    mt: [1, 0],
                    width: ["100%","200px"],
                    display: ["block","block","none"],
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    textTransform: "capitalize",
                    bgcolor: selectedTheme.colors.button.background,
                    color: 'white',
                    '&:hover': {
                        bgcolor: selectedTheme.colors.button.hover,
                    },
                }}
                    onClick={checkGeoLocationPermission}
                >
                    Nearby Adventures
                </Button>
                <Button
                    size="large"
                    sx={{
                        width: ["100%","200px"],
                        fontFamily:
                            selectedTheme.font,
                        fontWeight: "bold",
                        mt: 1,
                        color: selectedTheme.colors.text,
                    }}
                    onClick={clearFilters}
                >
                    Clear filters
                    </Button>
            </Box>
            {
                (loading) &&
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: "100%", height: 400 }}>
                    <CircularProgress size="5rem" />
                </Box>
            }
            <Box className="experiences-container">
                {

                    showMessageIllinois || showMessageActivateGelocation ?

                        (
                            <div className="center">
                                <Typography sx={{ fontWeight: 'bold', fontSize: 22, lineHeight: "22px", fontFamily: "Montserrat" }}>
                                    {showMessageIllinois && 'No experiences, try clear your filters and search by category'}
                                    {showMessageActivateGelocation && 'You Need to enable manually the ubication permission to be able to use this feature'}
                                </Typography>
                            </div>

                        )
                        :
                        experiences.length === 0 && !loading ?
                            (
                                <div className="center">
                                    <Typography sx={{ fontWeight: 'bold', fontSize: 22, lineHeight: "22px", fontFamily: "Montserrat" }}>
                                        There are no experiences matching your requirements.
                            </Typography>
                                </div>
                            )
                            :
                            experiences?.map((event: {
                                bannerImage: string,
                                title: string,
                                address: { city: string },
                                shortDescription: string,
                                theme: string,
                                id: string,
                                details?: any,
                                distanceToUser: number
                            }, index: number) => {

                                return (
                                    <Box key={index} sx={{ gridColumn: 'span 1' }}>
                                        <ExperienceCard
                                            bannerImage={event.bannerImage}
                                            title={event.title}
                                            city={event.address.city}
                                            shortDescription={event.shortDescription}
                                            theme={event.theme}
                                            id={event.id}
                                            details={event.details}
                                            ownerName={event.details.ownerName}
                                            distanceToUser={event.distanceToUser}
                                        />
                                    </Box>
                                )
                            })

                }
            </Box>
        </Box>
    )
}


const orderToursByProximity = (userLocation: [number, number], messyData: any): any => {


    const messyDataWithDistanceValue = messyData.map((d: any) => {
        return {
            ...d,
            distanceToUser: getDistanceTwoCoordinates(d.coordinates['_lat'], d.coordinates['_long'], userLocation[0], userLocation[1], 'K')
        }
    });

    let messyDataInRange70km = messyDataWithDistanceValue.filter((c: any) => c.distanceToUser < 70).sort(compareCoordDistance70);

    /*
    // ordering from smallest to largest
    let auxTour: object;
    for (let i = 0; i < messyDataInRange70km.length; i++) {
        for (let j = 0; j < messyDataInRange70km.length - 1; j++) {
            if (messyDataInRange70km[j].distanceToUser > messyDataInRange70km[j + 1].distanceToUser) {
                auxTour = messyDataInRange70km[j];
                messyDataInRange70km[j] = messyDataInRange70km[j + 1];
                messyDataInRange70km[j + 1] = auxTour;
            }

        }
    }
    */


    console.log("----", messyDataInRange70km.map((c: any) => c.distanceToUser))
    return messyDataInRange70km;
}


const compareCreated = (a: any, b: any): number => {
    if (a.createdAt < b.createdAt) {
        return -1;
    }
    if (a.createdAt > b.createdAt) {
        return 1;
    }
    return 0;
}

const comparePrice = (a: any, b: any): number => {
    if (a.details.pricing < b.details.pricing) {
        return -1;
    }
    if (a.details.pricing > b.details.pricing) {
        return 1;
    }
    return 0;
}

const compareDuration = (a: any, b: any): number => {
    if (a.duration < b.duration) {
        return -1;
    }
    if (a.duration > b.duration) {
        return 1;
    }
    return 0;
}

const compareRating = (a: any, b: any): number => {
    if (a.averageRating < b.averageRating) {
        return -1;
    }
    if (a.averageRating > b.averageRating) {
        return 1;
    }
    return 0;
}

const compareGuide = (a: any, b: any): number => {
    if (a.details.ownerName.replace(" ", "").toUpperCase() < b.details.ownerName.replace(" ", "").toUpperCase()) {
        return -1;
    }
    if (a.details.ownerName.replace(" ", "").toUpperCase() > b.details.ownerName.replace(" ", "").toUpperCase()) {
        return 1;
    }
    return 0;
}

const compareByLocation = (a: any, b: any): number => {

    if (a.address.city.toUpperCase() < b.address.city.toUpperCase()) {
        return -1;
    }
    if (a.address.city.toUpperCase() > b.address.city.toUpperCase()) {
        return 1;
    }
    return 0;
}

const compareTheme = (a: any, b: any): number => {

    if (a.theme.toUpperCase() < b.theme.toUpperCase()) {
        return -1;
    }
    if (a.theme.toUpperCase() > b.theme.toUpperCase()) {
        return 1;
    }
    return 0;
}


const compareCoordDistance70 = (a: any, b: any): number => {

    if (a.distanceToUser < b.distanceToUser) {
        return -1;
    }
    if (a.distanceToUser > b.distanceToUser) {
        return 1;
    }
    return 0;
}



export default HomeExperienceCards;

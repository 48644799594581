import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const configfb = {
	apiKey: "AIzaSyBaGOrP8CT0r0l2QYO-MNe5z1_v0L37GmQ",
	authDomain: "atlas-staging-dd4b0.firebaseapp.com",
	databaseURL: "https://atlas-staging-dd4b0.firebaseio.com",
	projectId: "atlas-staging-dd4b0",
	storageBucket: "atlas-staging-dd4b0.appspot.com",
	messagingSenderId: "30822768691",
	appId: "1:30822768691:web:425727d1cc9c69a5d60346",
	measurementId: "G-CR4X6JJ4EZ"
}

const configfbprod = {
	apiKey: "AIzaSyAIHptHNYmd2RWiWk_vfUJUb91OOYmSyto",
	authDomain: "atlas-f1b17.firebaseapp.com",
	databaseURL: "https://atlas-f1b17.firebaseio.com",
	projectId: "atlas-f1b17",
	storageBucket: "atlas-f1b17.appspot.com",
	messagingSenderId: "703884954153",
	appId: "1:703884954153:web:13d06aeee695a94e712a38"
	}

//firebase.initializeApp(configfb);
firebase.initializeApp(configfbprod);
export default firebase;

import React, { useState } from 'react';
import Header from '../components/Header'
import HomeExperienceCards from '../components/HomeExperienceCards';
import Box from "@mui/material/Box";
import "../css/home.css"
import {Helmet} from "react-helmet";  

const Home = () => {
	return (
		<div>
			<Helmet>
				<title>eAtlas Store</title>
				<meta name="description" content="Helmet application" />
				<meta property="og:title" content="eAtlas" />        
                <meta property="og:description" content="Best Products for your pet" />   
                <meta property="og:url" content="https://quotation.xeleva.com/" />
                <meta property="og:site_name" content="Pets - Products" />
				<meta property="og:image" content="https://xeleva.com/imgcalc1.jpg" />
			</Helmet>
			<Header currentPage={'Shop Experiences'}/>
			<Box className="all-container">
				<h1 className="home_title">Check out these Adventures!</h1>
				<h2 className="home_subtitle">Discover the best Adventures across the US</h2>
				<HomeExperienceCards/>
			</Box>		
    	</div>
	);
}

export default Home;
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import Header from '../components/Header';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close, ShoppingCartOutlined} from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import '../css/checkout.css';
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useShopping, useShoppingUpdate } from '../serviceContext/ShoppingContext'
import { IconButton } from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import {useTheme} from '../theme/useTheme';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_DOMAIN,
});


const CheckoutItem = ({properties}: any) =>{

    const shoppingList = useShopping();
    const shoppingProperties = useShoppingUpdate();
    const {theme, themeLoaded, getFonts} = useTheme();
    const [selectedTheme, setSelectedTheme] = React.useState<any>(theme);
    const discountPercentage: number = properties.discount !== undefined ? properties.discount.discount/100 : 0;
    const newPriceWithDiscount: number = properties.price - properties.price * (discountPercentage);
    const discountCode: string = properties.discount !== undefined ? properties.discount.code : "";
    React.useEffect(() => {
        setSelectedTheme(theme);
    }, [theme]);

    

    const ShrinkableItem = () => {
        if(window.innerWidth <= 768){
            return(
                <Box sx={{borderBottom: `1px solid ${selectedTheme.colors.primary}`, width: '100%'}}>
                    <Grid className="product">

                        <Grid  sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box  component="img" sx={{
                                width: 90,
                                height: 90
                            }}
                            src={properties.bannerImage}
                            >
                            </Box>
                            <Grid sx={{mt: 2}}>
                                <Typography variant="h5" component="div" gutterBottom>
                                    {properties.price! % 1 === 0 
                                    ? `$ ${properties.price}.00 USD` 
                                    : `$ ${properties.price} USD`}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Grid sx={{
                                textAlign: 'center'
                            }} >
                                <Typography variant="h6" component="div" gutterBottom>
                                    {properties.title}
                                </Typography>
                                <Typography variant="subtitle1" component="div" gutterBottom>
                                    {properties.city}
                                </Typography>
                            </Grid>

                            <Grid sx={{
                                textAlign: 'center'
                            }} >
                                <Typography variant="h6" component="div" gutterBottom>
                                    {properties.ownerName}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid  sx={{display: 'flex', flexDirection: 'column'}}>
                            <IconButton onClick={()=>{
                                shoppingProperties.removeItem(properties.id)
                            }} sx={{color: selectedTheme.colors.primary}}>
                                <Close sx={{fontSize: 25}}/>
                            </IconButton>
                        </Grid>

                    </Grid>
                </Box>
            );
          } else {
            return(
                <Box sx={{borderBottom: `1px solid ${selectedTheme.colors.primary}`, width: '100%'}}>
                    <Grid container className="product">

                        <div style={{width: '20%'}}>
                            <Box  component="img" sx={{
                                width: 100,
                                height: 100,
                                borderRadius: 5,
                            }}
                            src={properties.bannerImage}
                            >
                            </Box>
                        </div>

                        <div className="product-description">
                            <Grid item xs={4} sx={{
                                textAlign: 'center'
                            }} >
                                <Typography variant="h6" component="div" gutterBottom>
                                    {properties.title}
                                </Typography>
                                <Typography variant="subtitle1" component="div" gutterBottom>
                                    {properties.city}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} sx={{
                                textAlign: 'center'
                            }} >
                                <Typography variant="h6" component="div" gutterBottom>
                                    {properties.ownerName}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} sx={{
                                textAlign: 'center'
                            }} >
                                <Typography variant="h6" component="div" gutterBottom>
                                { properties.price! % 1 === 0 
                                    ? <p className={discountPercentage !== 0 ? "originalPrice" : ""}>$ {properties.price}.00 USD </p>
                                    : <p className={discountPercentage !== 0 ? "originalPrice" : ""}>$ {properties.price} USD</p>
                                }
                                {discountPercentage !== 0 ?
                                        <>
                                        {newPriceWithDiscount! % 1 === 0 
                                        ? <p className="discountPrice">$ {newPriceWithDiscount}.00 USD </p>
                                        : <p className="discountPrice">$ {newPriceWithDiscount} USD</p>}
                                        <div style={{fontSize: "12px"}}>
                                            Discount applied:
                                            <br/>
                                            <Chip label={discountCode}  />
                                            <Tooltip title="Remove discount" placement="right">
                                            <IconButton onClick={()=>{
                                                shoppingProperties.removeDiscount(properties.id)
                                            }} sx={{color: selectedTheme.colors.primary}}>
                                                <Close sx={{fontSize: 25}}/>
                                            </IconButton>
                                            </Tooltip>
                                        </div>
                                        </>
                                        : ""
                                }    
                                </Typography>
                            </Grid>
                        </div>

                        <div style={{textAlign: 'center', width: '10%'}}>
                        <Tooltip title="Remove item">
                            <IconButton onClick={()=>{
                                shoppingProperties.removeItem(properties.id)
                            }} sx={{color: "#000"}}>
                                <DeleteIcon sx={{fontSize: 25}}/>
                            </IconButton>
                        </Tooltip>
                        </div>

                    </Grid>
                </Box>
            );
          }
    }

    return(
        <ShrinkableItem/>
    );
}





const Shopping = () =>{

    const shoppingList = useShopping();
    const shoppingProperties = useShoppingUpdate();
    const {theme, themeLoaded, getFonts} = useTheme();
    const [selectedTheme, setSelectedTheme] = React.useState<any>(theme);

    const [, setCookie,  ] = useCookies(['payment']);
    const history = useHistory();
    let totalPrice = shoppingProperties.totalPrice;
    let totalDiscount = shoppingProperties.totalDiscount;
    let totalOriginal = shoppingProperties.totalOriginal;

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [email_error, setEmail_error] = useState(false);
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState(totalPrice)
    const [discount, setDiscount] = useState(0.00);
    const [errorMessageDiscount, setErrorMessageDiscount] = useState<string>("")
    const [adventuresFinalPrice, setadventuresFinalPrice] = useState<any[]>(shoppingList.map((tour: any) => JSON.parse(JSON.stringify(tour))))
    

    React.useEffect(() => {
        setSelectedTheme(theme);
    }, [theme]);

    React.useEffect(() => {
        if(shoppingProperties.totalPrice === 0){
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }
    }, []);

    React.useEffect(() => {
        if(shoppingProperties.totalPrice === 0){
            if(email !== ""){
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }
        } else {
            setDisabledButton(false);
        }

        setDiscount(0);
        if(shoppingProperties.discountValidCode)
        {
            checkCode(shoppingProperties.discountValidCode)
        }
        else{
            setTotalPriceWithDiscount(totalPrice)
        }
        
    }, [email, shoppingProperties.totalPrice]);


    const toExperiences = () => {
        history.push('/');
      }


    const paymentHandler = (event: React.MouseEvent<HTMLElement>) => {
        
        setLoading(true);
        
        const adventures = shoppingList.map(item => {
            let finalPrice: number;
            if(item.discount)
                finalPrice = Number(item.price)- Number(item.price) * (item.discount.discount /100);
            else
                finalPrice = Number(item.price)

            return (
            { guide_id: item.id,
              price: Math.round(finalPrice*100),
              name: item.title,
              code: item.id+"EXP",
              exp_id: item.id
            }
            ) 
        })
        if(!adventures || adventures.length === 0){
          alert("Please add items to your cart!")
          setLoading(false)
          return
        }
        let valid_email = String(email)
                        .toLowerCase()
                        .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );
        // test ids: 
        //ids = ["KtThVdlelTZZzkx9Xy7z","VsQfesIveaVM8VAuySyI"]
        var data = { experience_list: adventures, email: "", discountCode: ""}
        
        if(
            shoppingProperties.totalPrice === 0 &&
            (email === undefined || email === "" || valid_email === null)
        ){
          //alert("Please enter a valid email to receive your codes!")
          setLoading(false)
          setEmail_error(true)
          return
        }
        setEmail_error(false)
        if(shoppingProperties.totalPrice === 0 || totalPriceWithDiscount === 0)
            data.email = email
        const today = new Date().toLocaleDateString("en-US")
        setCookie('payment', today, { path: '/' });
        
        if(shoppingProperties.discountValidCode)
            data.discountCode = shoppingProperties.discountValidCode;
        
        api.post("/payment/create-checkout-session-list", data, {
          headers: { "content-type": "application/json" },
        })
        .then((res:{data:{url: string}}) => {
            //console.log("res", res)
          window.location.replace(res.data.url);
          //window.open(res.data.url, '_blank')
        })
        .catch((error:{response:any}) => {
          window.location.replace(error.response.data.url);
          //window.open(error.response.data.url, '_blank')
        });
    }



    const sendCode = async (e: any, ) => {
        e.preventDefault();
        shoppingProperties.setDiscountValidCode("");
        if(errorMessageDiscount.length > 0)
            setErrorMessageDiscount("")
        const inputHtml = document.getElementById("discount-input") as HTMLInputElement | null;
        const codeValue: string = inputHtml?.value || "";
        checkCode(codeValue)
    }


    const checkCode = async (codeValue: string) => {
        if(!codeValue){
            setErrorMessageDiscount("You must write a code")
            return;
        }

        api.post("/payment/check-discount", { discountCode: codeValue }, {
            headers: { "content-type": "application/json" },
          })
        .then(( res: any ) => {
            console.log("res", res);
            let discountInfo = res.data.discountInfo;
            //console.log("-res", res);
            //delete discountInfo.type;
            //discountInfo.event = "ggS8w0Lw2Aeb2kLYpnWh";

            shoppingProperties.setDiscountValidCode(codeValue);
            let idExperienceWithDiscount = ""
            if( typeof discountInfo.event === "string")
            {
                idExperienceWithDiscount = discountInfo.event;
            }
            else{
                if(discountInfo.event?.value)
                {
                    idExperienceWithDiscount = discountInfo.event.value;
                }

            }

            /*
            if(discountInfo?.isUsed)
            {
                console.log("The Coupon has already been used");
                setErrorMessageDiscount("Invalid code");
                //return;
            }
    
            if(!discountInfo.isActive)
            {
                console.log("The Coupon is no longer available")
                setErrorMessageDiscount("Invalid code");
                //return; // line commented due to some documents don't have this field, in production it will be uncommented
            }
            */

            /*
            const currentDate = new Date().getTime();
            if(currentDate < discountInfo.startDate || currentDate > discountInfo.endDate)
            {
                console.log("The Coupon is out of range")
                setErrorMessageDiscount("Invalid code");
                //return; // line commented due to some documents don't have this field, in production it will be uncommented
            }
            */
    
    
            // This code is needed because of another way it modifies the original ShoppingList array
            let auxAdventures: any[] = shoppingList.map((tour: any) => JSON.parse(JSON.stringify(tour)));

            //if(discountInfo.type !== "global")
            if(discountInfo.type === "global")
            {
                /*
                // discount on total price
                const discountPercentage: number = discountInfo.discountPercentage/100
                setTotalPriceWithDiscount( totalPrice * ( 1 - discountPercentage ) );
                setDiscount( totalPrice * discountPercentage )
                for(const tour of auxAdventures)
                {
                    if(tour?.price){
                        tour.price = tour.price * ( 1 - discountPercentage )
                    }
                }
                */

                if(idExperienceWithDiscount){
                    let newPriceWithDiscount: number = 0.0;
                    let originalPriceChanged: boolean = false;
                    for(const tour of auxAdventures)
                    {   
                        if(tour?.price){
                            if(tour.id === idExperienceWithDiscount)
                            //if(tour.id === "lo1TUdN8qOOLeeRjbzu3")
                            {   
                                originalPriceChanged = true;
                                const discountPercentage: number = discountInfo.discountPercentage/100;
                                newPriceWithDiscount += tour.price * ( 1 - discountPercentage);
                                setDiscount( tour.price * discountPercentage )
                                tour.price = tour.price * ( 1 - discountPercentage);
                                shoppingProperties.updateItem(tour.id, codeValue, discountInfo.discountPercentage)
                            }
                            else{
                                newPriceWithDiscount += tour?.price;
                            }
                        }
      
                    }
                    if(!originalPriceChanged)
                    {
                        console.log("The Coupon doesn't belong to any expereince")
                        setErrorMessageDiscount("Invalid code");
                    }
                    setTotalPriceWithDiscount(newPriceWithDiscount)
                }
                else{
                    //console.log("The Coupon is neither global ner has an experience defined")
                    console.log("The Coupon doesn't have an experience defined")
                    setErrorMessageDiscount("Invalid code");
                    return;
                }

            }
            else{
                console.log("The Coupon isn't valid for an user without account")
                setErrorMessageDiscount("Invalid code");
                return;
            }
            setadventuresFinalPrice(auxAdventures);
        })
        .catch((error) => {
            console.log("error", error);
            console.log("The Coupon doesn't exist")
            setErrorMessageDiscount("Invalid code")
        }) 
    }
 


    return(
        <Grid container sx={{
            height: '100%',
            mb: 5
        }} alignContent="center" columns={12} justifyContent="center">
                {/* TODO add header to the view */}
                <Grid item xs={12} >
                    <Header currentPage={'Shopping'}/>
                </Grid>
            <Grid  item xs={10} sx={{
                marginTop: 3
            }} >
            <Grid sx={{borderBottom: `1px solid ${selectedTheme.colors.primary}`}}  item xs={12} alignContent="center" justifyContent="center" >
                <Box>
                    <Typography variant="h4" sx={{
                        padding: 5,
                        color: selectedTheme.colors.text,
                        mt: 2
                    }} textAlign="left" >
                        My shopping cart
                    </Typography>
                </Box>
            </Grid>

            {/* NOTE Items */}
            <Divider />
            <Grid item  xs={12} sx={{
                backgroundColor: 'none'
            }} justifyContent="center" alignContent="center" >
                    <Grid container sx={{
                        backgroundColor: 'none'
                    }} justifyContent="center" >
                        <Grid item xs={12} sx={{
                            backgroundColor: 'none'
                        }}>
                            
                            {shoppingList.slice().map((item) => {
                                return(
                                    <CheckoutItem key={item.id} properties={item} />
                                )
                            })}
        
                        </Grid>

                        <Divider />
                        {(shoppingList.length > 0) && 
                        <div style={{
                            display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-end', 
                            justifyContent: 'space-around', marginTop: "20px"
                            }}>
                            <div className="prices">
                                <Typography variant="h6">
                                    Subtotal:
                                </Typography>
                                <Typography variant="h5" sx={{color: selectedTheme.colors.primary}}>
                                    {totalOriginal! % 1 === 0 
                                            ? `$ ${totalOriginal}.00 USD` 
                                            : `$ ${totalOriginal.toString().slice(0,totalOriginal.toString().indexOf(".")+3)} USD`}
                                </Typography>
                            </div>
                            <div className="prices">
                                <Typography variant="h6">
                                    Discount:
                                </Typography>
                                <Typography variant="h5" sx={{color: selectedTheme.colors.primary}}>
                                {totalDiscount! % 1 === 0 
                                            ? `$ ${totalDiscount}.00 USD` 
                                            : `$ ${totalDiscount.toString().slice(0,totalDiscount.toString().indexOf(".")+3)} USD`}
                                </Typography>
                            </div>
                            <div className="prices">
                                <Typography variant="h6">
                                    Items Count:
                                </Typography>
                                <Typography variant="h5" sx={{color: selectedTheme.colors.primary}}>
                                    {shoppingList.length}
                                </Typography>
                            </div>
                            <div className="prices">
                                <Typography variant="h6">
                                    Total:
                                </Typography>
                                <Typography variant="h5" sx={{color: selectedTheme.colors.primary}}>
                                {totalPrice! % 1 === 0 
                                            ? `$ ${totalPrice}.00 USD` 
                                            : `$ ${totalPrice.toString().slice(0,totalPrice.toString().indexOf(".")+3)} USD`}
                                </Typography>
                            </div>
                            <div className='container-form-error-msg'>
                                <form onSubmit={sendCode} className="form-discount-code" style={{ marginTop: "10px"}}>
                                    <TextField
                                        //error={email_error}
                                        id="discount-input"
                                        label="Discount code"
                                        //value={email}
                                        autoComplete="off"
                                        sx={{width: '100%' }}
                                        //onChange={(e) => {setEmail(e.target.value)}}
                                    />
                                    <Button
                                        type="submit"
                                        sx={{
                                            backgroundColor: selectedTheme.colors.button.background, 
                                            color: '#fff',
                                            boxShadow: 'none',
                                            textTransform: 'none',
                                            fontSize: "16px",
                                            borderRadius: "10px",
                                            outline: "none",
                                            ml: 2,
                                            '&:hover': {
                                                bgcolor:selectedTheme.colors.button.hover,
                                                
                                            }
                                        }}
                                    >
                                        Apply
                                    </Button>
                                </form>
                                {
                                    errorMessageDiscount.length > 0 &&
                                    <Typography 
                                    sx={{
                                        fontSize: "14px",
                                        color: "#b00",
                                        mt: 1,
                                        position: "absolute",
                                        top: 62,
                                        right: 0
                                        
                                    }}
                                    >
                                    { errorMessageDiscount }
                                    </Typography>

                                }
                            </div>
                            { (totalPrice === 0 || totalPriceWithDiscount === 0 ) &&
                            <div className="prices" style={{paddingBlock: '10px'}}>
                                <TextField
                                    error={email_error}
                                    required
                                    id="email_required"
                                    label="Email"
                                    value={email}
                                    sx={{width: '100%', }}
                                    helperText="Enter a valid email address"
                                    onChange={(e) => {setEmail(e.target.value)}}
                                />
                            </div>
                            }
                            <div className="btn-pay-container">
                                <LoadingButton
                                    sx={{backgroundColor: selectedTheme.colors.button.background, color: '#fff', width: '100%', boxShadow: 'none',
                                    textTransform: 'none',
                                    fontSize: "20px",
                                    lineHeight: "25px",
                                    borderRadius: "20px",
                                    '&:hover': {
                                        bgcolor:selectedTheme.colors.button.hover,
                                        
                                      }}}
                                    onClick={paymentHandler}
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<ShoppingCartOutlined sx={{fontSize: 30}}/>}
                                    variant="contained"
                                    disabled={disabledButton}
                                  >
                                    <Typography 
                                        sx={{fontSize: "20px"}}
                                    >
                                            Proceed to Pay
                                    </Typography>
                                </LoadingButton>
                            </div>
                        </div>
                    }
                     {(shoppingList.length === 0) &&
                     <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <h1>Your cart is empty</h1>
                    <Button sx={{
                        backgroundColor: selectedTheme.colors.button.background,
                        borderRadius: "20px",
                        color: selectedTheme.colors.button.text,
                        px: 5,
                        width: 250,
                        fontFamily: selectedTheme.font,
                        boxShadow: 'none',
                        textTransform: 'none',
                        fontSize: "20px",
                        lineHeight: "25px",
                        fontWeight: 'bold',
                        mb: 2, '&:hover': {
                          bgcolor: '#d6627e',
                        },
                      }}
                        onClick={toExperiences}
      
                      >Continue Shopping</Button>
                      </div>
                    } 
                    
                    </Grid>
            </Grid>

            </Grid>

        </Grid>
    )
}

export default Shopping;

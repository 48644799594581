import React, {useEffect, useState} from 'react'
import {
    Card,
    CardContent,
    CardMedia,
    Button,
    Box,
    Link,
    Typography
} from '@mui/material'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { useShopping, useShoppingUpdate } from '../serviceContext/ShoppingContext';
import '../css/experience.css';
import {useTheme} from '../theme/useTheme';
import iconEatlas from '../assets/images/eATLAS_favicon_64x64.png';
import { useHistory } from "react-router-dom";


interface ExperienceCardProps {
	bannerImage:string, 
	title: string,
    city:string,
    shortDescription: string,
    theme: string,
    price?: number,
    id?: string,
    details?: any,
    ownerName?: string,
    distanceToUser?: number
}

const ExperienceCard = (props: ExperienceCardProps) => {

    const shoppingList = useShopping();
    const shoopingFunctions = useShoppingUpdate();
    const [shoppingCartIds, setShoppingCartIds] = useState<Array<String>>([]);
    const {theme, themeLoaded, getFonts} = useTheme();
    const [selectedTheme, setSelectedTheme] = React.useState<any>(theme);
    const history = useHistory();

     let experienceTypes: any = {};
     experienceTypes['guided_tour'] = "Guided Tour";
     experienceTypes['treasure_hunt'] = "Scavenger Hunt";
     experienceTypes['food_experience'] = "Food Adventure";
     experienceTypes['date_night'] = "Date Nights";
     experienceTypes['drink_tour'] = "Drink Adventure";

     let nameWithDashes: string = props.title;
     nameWithDashes = nameWithDashes.replace(/\s+/g, '-');

    const addToShoppingList = () => {
        let itemExperience = {
            price: props.details.pricing,
            title: props.title,
            id: props.id,
            city: props.city,
            bannerImage: props.bannerImage,
            ownerName: props.ownerName
        }
        shoopingFunctions.addItem(itemExperience)
    }

    const fillIdsArray = () => {
        let idsArray: Array<String> = [];
        for(let item of shoppingList){
            idsArray.push(item.id);
        }
        setShoppingCartIds(idsArray);
    };

    useEffect(()=>{
        fillIdsArray();
    }, [shoppingList]);

    const CustomButton = () => {
        if(shoppingCartIds.includes(props.id!.toString())){
            return <>
                <Button  onClick={addToShoppingList} variant='contained' sx={{
                            mb: 3,
                            fontSize: "20px",
                            lineHeight: "30px",
                            textAlign: 'center',
                            borderRadius: "20px",
                            fontWeight: 'bold',
                            bgcolor:'secondary.main',
                            color: 'white',
                            fontFamily: selectedTheme.font,
                            boxShadow: 'none',
                            textTransform: 'none',
                                }} disabled={true}>
                    Already in your cart
                </Button>
            </>                  
        } else {
            return <>
            <Button  onClick={addToShoppingList} variant='contained' sx={{
                        mb: 3,
                        textAlign: 'center',
                        borderRadius: "20px",
                        fontWeight: 'bold',
                        bgcolor: selectedTheme.colors.button.background,
                        color: 'white',
                        fontFamily: selectedTheme.font,
                        boxShadow: 'none',
                        textTransform: 'none',
                        width: '70%',
                        fontSize: "20px",
                        zIndex: '100',
                        position: 'relative',
                        lineHeight: "30px",
                        '&:hover': {
                            bgcolor: selectedTheme.colors.button.hover,
                          },
                            }}>
                Buy now <img src={iconEatlas} style={{width: 30, height: 30}} />
            </Button>
            </>
        }
    };

    const goToPage = () => {
        //history.push("/view/" + props.id + "/" + nameWithDashes);
        window.open("/view/" + props.id + "/" + nameWithDashes);
    }

	return (
            <Card 
            className="experience-card"
            sx={{
                    borderRadius:3,
                    boxShadow:8,
                    width: '100%',
                    cursor: 'pointer'
                }}
                
                >
            <CardMedia
                className={"experience-image"}
                component="img"
                onClick={goToPage}
                image={props.bannerImage}/>
                    <CardContent
                     sx={{height: 200, color: "#2d2d2d"}}
                     onClick={goToPage}
                    >
                        <div className="card-container">
                            <Box>
                                <Typography sx={{fontWeight: 'bold', fontSize: 22, lineHeight: "22px", fontFamily: "Montserrat"}}>
                                    {props.title}
                                </Typography>
                                <Box sx={{ 
                                    fontStyle: 'italic',
                                    fontWeight: 'light',
                                    fontSize: '16px',
                                    mb: 1,
                                    mt: 1,
                                    color:'gray'
                                    }}>
                                    {props.city}
                                </Box>
                            </Box>
                            
                            <Box sx={{ 
                                fontWeight: 'light',
                                fontSize: '14px',
                                color: "#525252",
                                mb: 1
                                }}>
                                    <b>Category: </b>{experienceTypes[props.details.type]}
                            </Box>
                            <Box sx={{height: '75px', overflow: 'hidden'}}>
                                
                                    {props.shortDescription}
                                
                            </Box>
                        </div> 
                    </CardContent>      
                    {/*<p>{props.distanceToUser}</p>*/}
                    <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', zIndex: '1000',
                        position: 'relative'}}>
                           	<CustomButton/>
                    </Box>

            </Card>
	)
}

export default ExperienceCard;

import React, { useState, useRef, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import {useTheme} from '../theme/useTheme';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TextField from '@mui/material/TextField';
import { Search, AccountCircle } from '@mui/icons-material';
import { InputLabel } from '@mui/material';
import '../css/chipinput.scss';

interface propsChipInput {
    wordsList: string[],
    inputName: string,
    onDelete: Function,
    onAdd: Function
}

const ChipInput = ({ wordsList, inputName, onDelete, onAdd }: propsChipInput) => {

  const [currentInputWord, setCurrentInputWord] = useState<string>("");
  const {theme, themeLoaded, getFonts} = useTheme();
  const [moveToBottom, setMoveToBottom] = useState(false);
  const [selectedTheme, setSelectedTheme] = React.useState<any>(theme);



  const addWord = (e: any) => {
    if(e.key === "Enter")
    {
        if(!wordsList.includes(e.target.value))
        {
            setCurrentInputWord("")
            onAdd(e.target.value);
        }
    }    
  }

  const addWordByClick = () => {
    const word = document.getElementById("chip-input") as HTMLInputElement | null;
    if(word?.value)
    {
      if(!wordsList.includes(word?.value))
      {
          setCurrentInputWord("")
          onAdd(word?.value);
      }
    }
  }



  return (
    <div style={{ "position": "relative", "width": "100%" }}>
        <label 
          htmlFor="chips-input"
          placeholder={ inputName }
          className="chip-input-custom"
        >
          <Search sx={{ 
              color: 'action.active', 
              mr: 0.5, my: 0.5,
              cursor: "pointer"
            }} 
            onClick={ addWordByClick }
          />
                <p
                  className={ moveToBottom ? "aux-chip-input-label animation-below-name" : ( ( wordsList.length > 0 ) ? `chip-input-label-focused`: "chip-input-label") }
                  //className={ ( wordsList.length > 0 ) ? `chip-input-label-focused`: "chip-input-label" }
                >
                  { inputName }
                </p>

            {
                wordsList.map((word, index) => {
                    return (
                        <Chip 
                            sx={{ 
                                m: 0.5,
                                color:  `${selectedTheme.colors.body}`, 
                                fontSize: 14, 
                                backgroundColor: `${selectedTheme.colors.button.background}` 
                            }}
                            label={word}
                            key={index}
                            deleteIcon={<HighlightOffIcon  style={{ "color": "fff"}} />}
                            onDelete={() => {
                              if(wordsList.length === 1)
                              {
                                setMoveToBottom(true)
                                setTimeout(() => {
                                  setMoveToBottom(false)
                                }, 210);
                              }
                              onDelete(word, index)
                            }}
                        />
                    )
                })
            }
            <input 
              id="chip-input"
              type="text"
              autoComplete="off"
              value={currentInputWord}
              onChange={(e) => setCurrentInputWord(e.target.value)}
              onKeyPress={addWord}
              onFocus={() => { 
                if(moveToBottom)
                  setMoveToBottom(false);
               }}
              onBlur={() => { }}
              className="chip-input-text"
            />
        </label>
    </div>
  )
}

export default ChipInput
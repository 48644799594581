import React, { useState, useEffect } from 'react';
import firebase from "../firebase/firebase";
import { useParams } from 'react-router-dom';
import {
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    Typography
} from '@mui/material'
import ExperienceCard from '../components/ExperienceCard';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../components/Header'

type paramsGuide = {
    ownername: string,
    ownerid: string
}

const ExperiencesGuide = () => {

    const [loading, setLoading] = useState(true);
    const [experiences, setExperiences] = useState([])

    const params = useParams<paramsGuide>();
    params.ownername = params.ownername.replaceAll('-', ' ');

    useEffect(() => {

        getExperiences()
            .then((data: any) => {
                const { ownername, ownerid } = params;
                setExperiences(data.filter((c: any) => {
                    return c.details.ownerName === ownername && c.details.owner === ownerid
                }));
            }).finally(() => { setLoading(false) });

    }, [])
    
    const getExperiences = async (): Promise<any> => {
        const db = firebase.firestore();
        // You can get the collectionName and documentName from the shortcode attribute
        let data: any[] = []
        const snapshot = await db.collection('events').get();
        snapshot.docs.forEach(async (doc: any) => {
            if (doc.data().details) {
                if (doc.data().details.status === 'published') {
                    if (!doc.data().isDeleted) {
                        data.push(doc.data())
                    }
                }
            }
        })

        return data;
    }

  return (
    <Box sx={{ p: 8}}>
        <Header currentPage={'Guide Experiences'}/>
        {
        (loading) &&
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: "100%", height: 400 }}>
                <CircularProgress size="5rem" />
            </Box>
        }
        {
            (experiences.length > 0 && !loading) &&
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                <Typography sx={{ 
                    fontWeight: 'bold', 
                    fontSize: 22, 
                    lineHeight: "22px", 
                    fontFamily: "Montserrat",
                    mt: 10
                }}>
                    {`${params.ownername}'s Portfolio`}
                </Typography>
            </Box>

        }
                    <Box className="experiences-container">
                {
                    
                        experiences.length === 0 && !loading ?
                            (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                    <Typography sx={{ 
                                        fontWeight: 'bold', 
                                        fontSize: 22, 
                                        lineHeight: "22px", 
                                        fontFamily: "Montserrat",
                                        mt: 10
                                    }}>
                                        There are no experiences.
                                    </Typography>
                                </Box>
                            )
                            :
                            experiences?.map((event: {
                                bannerImage: string,
                                title: string,
                                address: { city: string },
                                shortDescription: string,
                                theme: string,
                                id: string,
                                details?: any,
                                distanceToUser: number
                            }, index: number) => {

                                return (
                                    <Box key={index} sx={{ gridColumn: 'span 1' }}>
                                        <ExperienceCard
                                            bannerImage={event.bannerImage}
                                            title={event.title}
                                            city={event.address.city}
                                            shortDescription={event.shortDescription}
                                            theme={event.theme}
                                            id={event.id}
                                            details={event.details}
                                            ownerName={event.details.ownerName}
                                            distanceToUser={event.distanceToUser}
                                        />
                                    </Box>
                                )
                            })

                }
            </Box>
    </Box>
  )
}

export default ExperiencesGuide
import { useEffect, useState } from 'react';
import { setToLS, getFromLS } from '../utils/storage';
import _ from 'lodash';

export const useTheme = () => {
  const themes = getFromLS('all-themes');
  const [themeName, setThemeName] = useState<String>("light");
  const [theme, setTheme] = useState<any>(themes.light);
  const [themeLoaded, setThemeLoaded] = useState<Boolean>(false);

  const setMode = (mode: any) => {
    setToLS('theme', mode)
    setTheme(mode);
  };

  const switchTheme = (): void => {
    if(themeName === "Light"){
      setMode(themes.dark);
      setThemeName("Dark");
    } else if(themeName === "Dark"){
      setMode(themes.light);
      setThemeName("Light");
    }
    window.location.reload();
  }

  const getFonts = () => {
    const allFonts = _.values(_.mapValues(themes, 'font'));
    return allFonts;
  }

  useEffect(() =>{
    const localTheme = getFromLS('theme');
    localTheme ? setTheme(localTheme) : setTheme(themes.light);
    localTheme ? setThemeName(localTheme.name) : setThemeName(themes.light.name);
    setThemeLoaded(true);
  }, []);

  return { theme, themeLoaded, themeName, setMode, getFonts, switchTheme };
};
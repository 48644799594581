import React from 'react';
import Header from '../components/Header';
import {
	Box, Link
  } from "@mui/material";
  
const NotFound = () => {
	return (
		<div>
			<Header currentPage={'PaymentResult'}/>
			<Box sx={{ m: "100px auto", width: "900px" }}>
				<h1>Page Not Found!</h1>
				<Link href="/">Go back to experiences</Link>
			</Box>	
		</div>
	);
}

export default NotFound;
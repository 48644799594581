export const setToLS = (key, value) => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
  
  export const getFromLS = key => {
    const value = window.sessionStorage.getItem(key);

    if (value) {
      return JSON.parse(value);
    }
  }